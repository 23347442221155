<template>
  <div class="app-container excel">
    <div>
    <el-row class="box search mt20" :span="24">
      <el-form :inline="true" :model="page" class="w" status-icon>
        <el-col :span="4">
          <el-form-item size="small" >
            <el-input placeholder="请输入标记企业名称" v-model="listQuery.company_name" clearable class="input-with-select"
                      @clear="onSearch">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item size="small">
            <el-input placeholder="请输入冻库名称" v-model="listQuery.warehouse_name" clearable class="input-with-select"
                      @clear="onSearch">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="1" class="tl mr10" >
          <el-form-item size="small">
            <el-button type="primary" @click="onSearch" class="general_bgc general_border cfff">查询</el-button>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
    </div>
    <el-table :data="list" v-loading="listLoading" style="width: 100%">
      <el-table-column
        label="全省食品生产经营主体及第三方冷贮服务提供者冷库信息表"
        align="center"
      >
        <el-table-column label="企业名称" prop="company_name" align="center">
        </el-table-column>
        <el-table-column label="冷库名称" prop="name" align="center">
        </el-table-column>
        <el-table-column label="冷库地址" prop="address" align="center">
        </el-table-column>
        <el-table-column label="冷库总面积（m²）" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.warehouse_area }}</span>
            <!--            <span>{{-->
            <!--                scope.row.freezing_storage &&-->
            <!--                scope.row.freezing_storage.area_count-->
            <!--                  ? scope.row.freezing_storage.area_count-->
            <!--                  : 0-->
            <!--              }}</span>-->
          </template>
        </el-table-column>
        <el-table-column label="冷库总容积（m³）" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.warehouse_volume }}</span>
            <!--            <span>{{-->
            <!--                scope.row.freezing_storage &&-->
            <!--                scope.row.freezing_storage.volume_count-->
            <!--                  ? scope.row.freezing_storage.volume_count-->
            <!--                  : 0-->
            <!--              }}</span>-->
          </template>
        </el-table-column>
        <el-table-column label="负责人" align="center">
          <template slot-scope="scope">
          <span>{{scope.row.warehouse_principal}}</span>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        layout="total, prev, pager, next, jumper"
        :total="listTotal"
      ></el-pagination>
    </div>
  </div>
</template>


<script>
  import axios from "@/util/api";

export default {
  data() {
    return {
      listLoading: false,
      list: [],
      listQuery: {
        opt: "1",
        pager_offset: "0",
        company_name:"",
        warehouse_name:"",
      },
      currentPage: 1,
      listTotal: 0,
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    onSearch(){
      this.initData();
    },
    get_count(contents) {
      return contents.length;
    },
    get_area_sum(contents) {
      let area = 0;
      contents.forEach((item) => {
        area += parseInt(item.area);
      });
      if (Number.isFinite(area))
        return area;
      else return 0;
    },
    get_volume_sum(contents) {
      let volume = 0;
      contents.forEach((item) => {
        volume += parseInt(item.volume);
      });
      if (Number.isFinite(volume))
        return volume;
      else return 0;
    },
    initData() {
      this.listLoading = true;
      axios.get("/pc/warehouse-statistics/freezerMsgStatistics/2",this.listQuery).then((response) => {
        this.list = response.data.results;
        this.listTotal = response.data.total;
        this.listLoading = false;
      });
    },
    handleSizeChange(val) {
      this.listQuery.count = val;
      this.initData();
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.listQuery.pager_offset = String((val - 1) * 10);
      this.initData();
    },
    handleView(id) {
      this.$router.push({
        name: "FreezingRegInfo",
        params: {
          id,
        },
      });
    },
  },
};
</script>
